import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import ContextProvider from '../components/ContextProvider/ContextProvider'
import logoSmall from '../static/images/logo_small.svg'

import './404.scss'

const NotFoundPage = props => (
  <ContextProvider>
    <Layout {...props} >
      <div className="b-404">
          <div className="b-404__content">
            <img className="b-404__logo" src={logoSmall} alt="sharekey" />
            <div>
              <h1 className="b-404__header">Oops!</h1>
              <p className="b-404__paragraph">Sorry, the page you were<br />
                looking for doesn’t exist.</p>
            </div>
          </div>
      </div>
    </Layout>
  </ContextProvider>
)

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    mainNav: contentfulNav(
      name: { eq: "main-top-bar-nav" }
      node_locale: { eq: "en-US" }
    ) {
      pages {
        slug
        nameInNav
        id
      }
    }
  }
`
